<!-- © 2021 Tfarraj -->
<template>
  <div>
    <v-dialog
      eager
      v-model="dialog"
      persistent
      transition="fab-transition"
      max-width="800"
    >
      <v-card tile>
        <div class="d-flex justify-space-between mb-5" id="modal-header">
          <v-card-title v-if="videoId" class="py-3">
            {{ $t('report.reportVideo') }}
          </v-card-title>
          <v-card-title v-if="channelId" class="py-3">
            {{ $t('report.reportChannel') }}
          </v-card-title>
          <v-btn icon text @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div v-if="!isSubmitted">
          <ValidationObserver ref="reportForm" v-slot="{ handleSubmit, reset }">
            <form
              @submit.prevent="handleSubmit(submitReportForm)"
              @reset.prevent="reset"
            >
              <v-card-text class="py-3">
                <ValidationProvider
                  name="reason"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div>
                    <v-select
                      name="reason"
                      :error-messages="
                        errors != '' ? $t('report.' + errors) : ''
                      "
                      :items="
                        reasons.map((v) => ({
                          key: v.key,
                          value: $t('report.' + v.value),
                        }))
                      "
                      v-model="reportFormData.reason"
                      item-value="key"
                      item-text="value"
                      v-bind:label="$t('report.selectReason')"
                    >
                    </v-select>
                  </div>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions class="py-3">
                <v-btn
                  :loading="loading"
                  color="indigo darken-4"
                  type="submit"
                  class="primary mt-1"
                  depressed
                >
                  {{ $t('report.submit') }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </div>
        <div v-if="isSubmitted">
          <v-card-text class="py-3">
            <v-alert dense outlined prominent text type="success">
              {{ $t('report.submitted') }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="py-3">
            <v-card-actions class="py-3">
              <v-btn color="indigo darken-4" @click="closeModal">
                {{ $t('dialogModal.close') }}
              </v-btn>
            </v-card-actions>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoService from '@/services/VideoService';

export default {
  name: 'ReportModal',
  props: {
    openDialog: {
      type: Boolean,
      required: true,
    },
    videoId: {
      type: String,
    },
    channelId: {
      type: String,
    },
  },
  data() {
    return {
      isSubmitted: false,
      loading: false,
      reportFormData: {
        reason: '',
      },
      reasons: [
        { value: 'Sexual content', key: 'Sexual content' },
        {
          value: 'Violent or repulsive content',
          key: 'Violent or repulsive content',
        },
        {
          value: 'Hateful or abusive content',
          key: 'Hateful or abusive content',
        },
        { value: 'Harassment or bullying', key: 'Harassment or bullying' },
        {
          value: 'Harmful or dangerous acts',
          key: 'Harmful or dangerous acts',
        },
        { value: 'Child abuse', key: 'Child abuse' },
        { value: 'Promotes terrorism', key: 'Promotes terrorism' },
        { value: 'Spam or misleading', key: 'Spam or misleading' },
      ],
    };
  },
  computed: {
    dialog() {
      return this.openDialog;
    },
  },
  methods: {
    async submitReportForm() {
      if (this.loading) return;
      this.loading = true;

      try {
        if (this.videoId) {
          const report = await VideoService.reportVideo({
            videoId: this.videoId,
            reason: this.reportFormData.reason,
          });
        } else if (this.channelId) {
          const report = await ChannelService.reportChannel({
            channelId: this.channelId,
            reason: this.reportFormData.reason,
          });
        }
        if (!report) return;
      } catch (err) {
      } finally {
        this.loading = false;
      }
      this.isSubmitted = true;
    },
    closeModal() {
      this.$emit('closeDialog');
    },
  },
};
</script>
<style lang="scss">
#modal-header {
  border-bottom: 1px solid rgb(43, 40, 40);
}
</style>
